ul {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
}

ul li {
  position: relative;
  list-style: none;
  width: 35px;
  height: 35px;
  margin: 0 30px;
  transform: rotate(-30deg) skew(25deg);
  background: #ccc;
}

ul li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  transition: 0.5s;
  color: #fff;
  font-size: 20px !important;

  display: flex !important;
  align-items: center;
  justify-content: center;
}

ul li:hover span {
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.1);
}

ul li:hover span:nth-child(5) {
  transform: translate(40px, -40px);
  opacity: 1;
}

ul li:hover span:nth-child(4) {
  transform: translate(30px, -30px);
  opacity: 0.8;
}

ul li:hover span:nth-child(3) {
  transform: translate(20px, -20px);
  opacity: 0.6;
}

ul li:hover span:nth-child(2) {
  transform: translate(10px, -10px);
  opacity: 0.4;
}

ul li:hover span:nth-child(1) {
  transform: translate(0, 0);
  opacity: 0.2;
}

ul li:nth-child(1) span {
  background: #333;
}

ul li:nth-child(2) span {
  background: #0077b5;
}

ul li:nth-child(3) span {
  background: #0077b5;
}

ul li:nth-child(4) span {
  background: #0077b5;
}

ul li:nth-child(5) span {
  background: #e4405f;
}
